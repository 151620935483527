import React from 'react'
import Image from 'gatsby-image'

const Trainers = ({instructors,isEdges}) => {
    return (
        <div>
        <div className="AboutInstructors">
          <div className="container">
            <h4 className="title">Our Instructors</h4>
            {isEdges === true ? (
              <div>
                  {instructors.map(({node})=>(
                      <div className="InstructorRow" key={node.id}>
                      <div className="instructorImg">
                        <Image 
                        fluid={node.image.fluid} 
                        className="img-fluid"
                        alt={node.name}
                         />
                      </div>
                      <div className="Ins_details">
                        <h5 className="ins_name">{node.name}</h5>
                        <p className="Experience">{node.experience}</p>
                        <p className="technologies">
                          {node.technologies}
                        </p>
                        <p className="ins_breif">
                          {node.details}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              instructors.map(instructorData => (
                <div className="InstructorRow" key={instructorData.id}>
                  <div className="instructorImg">
                    <img
                      src={instructorData.image.fluid.srcWebp}
                      alt={instructorData.name}
                      className="img-fluid"
                    />
                  </div>
                  <div className="Ins_details">
                    <h5 className="ins_name">{instructorData.name}</h5>
                    <p className="Experience">{instructorData.experience}</p>
                    <p className="technologies">{instructorData.technologies}</p>
                    <p className="ins_breif">{instructorData.details.details}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
}

export default Trainers
