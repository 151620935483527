import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Trainer from "../components/Trainer/Trainers"
import TrainersQuery from '../query/TrainersQuery'
const Trainers = () => {
  const trainerData= TrainersQuery()
  return (
    <Layout>
      <SEO title="Trainers" />
      <Trainer instructors={trainerData.Instructors.edges} isEdges={true}/>
    </Layout>
  )
}

export default Trainers
