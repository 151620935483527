import { graphql, useStaticQuery } from "gatsby"

const TrainersQuery = () => useStaticQuery(graphql`
query{
    Instructors: allContentfulBrainyFiedInstructors {
      edges {
        node {
          id
          experience
          name
          technologies
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }`)

export default TrainersQuery;